import clsx from 'clsx';
// Local Imports
import {fCurrency, fPercent} from '../../utils/formatNumber';
import {formatDate} from '../../utils/DateUtils';
// MUI
import {
    GridCellParams,
    GridColDef,
    GridColTypeDef,
    GridComparatorFn,
    GridValueFormatterParams
} from '@mui/x-data-grid'
// Components
import {LinkedRow} from './components';
import {SaveStatus} from "../../types/GeneralTypes";

// ----------------------------------------------------------------------
// Formatters

// Column Formatter for currency
function currencyFormatter(params: GridValueFormatterParams): string {
    if (params.value === '' || !params.value) return '';
    return fCurrency(params.value);
}

// Column Formatter for Percentages
function percentageFormatter(params: GridValueFormatterParams): string {
    return fPercent(params.value as number);
}

// Column Formatter for dates
function dateFormatter(params: GridValueFormatterParams): string {
    if (params.value) {
        return formatDate(params.value as string);
    } else {
        return '';
    }
}

// ----------------------------------------------------------------------
// Styling

export const dataGridFormatting = {
    '& .currency.negative': {
        color: 'red'
    },
    '& .currency.positive': {
        color: 'inherit'
    },
    '& .status.new': {
        bgcolor: 'success.lighter'
    },
    '& .status.edited': {
        bgcolor: 'warning.lighter'
    },
    '& .editable': {
        bgcolor: 'grey.300',
        borderColor: 'grey.300',
        color: 'inherit',
        "&:hover": {
            bgcolor: 'secondary.light',
            color: 'common.white',
            cursor: "pointer"
        },
        "& .MuiDataGrid-editInputCell": {
            "&:hover": {
                bgcolor: 'secondary.light',
                color: 'common.white',
                cursor: "pointer"
            },
        }
    },
}

// ----------------------------------------------------------------------
// Data Table Types

export const valueCurrency: GridColTypeDef = {
    type: 'number',
    minWidth: 100,
    align: 'right',
    valueFormatter: currencyFormatter,
    cellClassName: (params: GridCellParams) => {
        if (params.isEditable) {
            return clsx('editable currency', {
                negative: (params.value as number) < 0,
                positive: (params.value as number) >= 0
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            })
        }
        return clsx('currency', {
            negative: (params.value as number) < 0,
            positive: (params.value as number) >= 0
        }, ' status', {
            new: (params.row.status === SaveStatus.NEW),
            edited: (params.row.status === SaveStatus.EDITED)
        })
    }
}

export const valuePercentage: GridColTypeDef = {
    type: 'number',
    minWidth: 75,
    align: 'right',
    valueFormatter: percentageFormatter,
    cellClassName: (params: GridCellParams) => {
        if (params.isEditable) {
            return clsx('editable currency', {
                negative: (params.value as number) < 0,
                positive: (params.value as number) >= 0
            }, ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            })
        }
        return clsx('currency', {
            negative: (params.value as number) < 0,
            positive: (params.value as number) >= 0
        }, ' status', {
            new: (params.row.status === SaveStatus.NEW),
            edited: (params.row.status === SaveStatus.EDITED)
        })
    }
}

export const valueDate: GridColTypeDef = {
    type: 'date',
    width: 150,
    align: 'right',
    valueFormatter: dateFormatter,
    cellClassName: (params: GridCellParams) => {
        if (params.isEditable) {
            return clsx('editable', ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            })
        } else {
            return clsx(' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        }
    }
}

export const defaultFormat: GridColTypeDef = {
    cellClassName: (params: GridCellParams) => {
        if (params.isEditable) {
            return clsx('editable', ' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            })
        } else {
            return clsx(' status', {
                new: (params.row.status === SaveStatus.NEW),
                edited: (params.row.status === SaveStatus.EDITED)
            });
        }
    }
}

export const linkedRow: GridColDef = {
    field: '',
    width: 1,
    disableExport: true,
    sortable: false,
    filterable: false,
    renderCell: LinkedRow,
    cellClassName: (params: GridCellParams) => {
        return clsx(' status', {
            new: (params.row.status === SaveStatus.NEW),
            edited: (params.row.status === SaveStatus.EDITED)
        });
    }
}

export const actionRow: GridColDef = {
    field: '',
    width: 60,
    disableExport: true,
    sortable: false,
    filterable: false,
    cellClassName: (params: GridCellParams) => {
        return clsx(' status', {
            new: (params.row.status === SaveStatus.NEW),
            edited: (params.row.status === SaveStatus.EDITED)
        });
    }
}

export const generalRow: GridColTypeDef = {
    cellClassName: (params: GridCellParams) => {
        return clsx(' status', {
            new: (params.row.status === SaveStatus.NEW),
            edited: (params.row.status === SaveStatus.EDITED)
        });
    }
}

export const stringSortComparator: GridComparatorFn<any> = (a: string, b: string) => ('' + a).localeCompare(b);