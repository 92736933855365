import axios from 'axios'

const axiosInstance = {
    auth: axios.create({
        baseURL: process.env.REACT_APP_AWS_MCP_API_AUTH,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_AWS_MCP_API_KEY_AUTH
        },
        withCredentials: true
    }),
    mipad: axios.create({
        baseURL: process.env.REACT_APP_AWS_MCP_API_MIPAD,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_AWS_MCP_API_KEY_MIPAD
        },
        withCredentials: true
    })
}

// GENERIC HANDLER FOR AUTH API GET REQUESTS
export async function apiAuthGetRequests(query: string): Promise<any> {
    const response = await axiosInstance.auth.get(query);
    return response.data;
}

// GENERIC HANDLER FOR AUTH API GET REQUESTS
export async function apiAuthPostRequests(query: string, body?: any): Promise<any> {
    const response = await axiosInstance.auth.post(query, body);
    return response.data;
}

// GENERIC HANDLER FOR ALL API GET REQUESTS
export async function apiGetRequests(query: string): Promise<any> {
    const response = await axiosInstance.mipad.get(query);
    return response.data;
}

/**
 * Synchronous get form multiple api queries
 * Function will receive an objet of query strings and when complete will return in same format
 * @param queries - takes an object of queries with the api call in string
 */
export async function multipleAsyncGetRequest(queries: { [x: string]: string }) {
    const results: { [x: string]: any } = {};

    const queryPromises = Object.entries(queries).map(async ([key, value]) => results[key] = await apiGetRequests(value));

    await Promise.all(queryPromises)

    return results
}

// GENERIC HANDLER FOR ALL API GET REQUESTS
export async function apiPostRequests(query: string, body?: any): Promise<any> {
    const response = await axiosInstance.mipad.post(query, body);
    return response.data;
}