import {memo, useEffect, useState} from "react";
import {addDays, checkDateSame, formatDate} from "../../utils/DateUtils";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
// STORE
import {useAppDispatch, useAppSelector} from "../../store/store";
import {selectFund} from "../../store/afr/afrSlice";
import {selectDateSetsString} from "../../store/afr/afrSelector";
import {AlertDialog} from "../../components";
import {changeAFRDates} from "../../store/afr/afrFunction";

// Navigation Bar for AFR Page handling date and funds selection
function AFRNavBar() {
    const dispatch = useAppDispatch();
    const fund = useAppSelector(state => state.afr.fund);
    const funds = useAppSelector(state => state.afr.funds);
    const dataSets = useAppSelector(state => selectDateSetsString(state));
    const date = useAppSelector(state => state.afr.date)
    const previousDate = useAppSelector(state => state.afr.previousDate)
    const loading = useAppSelector(state => state.afr.loading);

    const [selectDate, setDate] = useState<Date | null>(null)
    const [selectPreviousDate, setPreviousDate] = useState<Date | null>(null)

    const [dateChangeWarning, setDateChange] = useState<boolean>(false)

    const checkDisable = (date: Date) => {
        return !dataSets.includes(formatDate(date, 'yyyy-MM-dd'));
    }

    // Set Select Date
    useEffect(() => {
        if ((!selectDate && date && previousDate)) {
            setDate(new Date(date))
            setPreviousDate(new Date(previousDate))
        }
    }, [date, selectDate, previousDate, setDate, selectPreviousDate])

    return (
        <>
            <Box sx={{flex: 1, pl: 2, pb: 1}}>
                <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                    {selectDate && selectPreviousDate && date && previousDate ?
                        <>
                            <Grid item md={3} sx={{p: 1}}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        value={selectPreviousDate}
                                        onChange={(event) => {
                                            setPreviousDate(event);
                                        }}
                                        format="dd/MM/yyyy"
                                        shouldDisableDate={checkDisable}
                                        maxDate={addDays(selectDate, -1)}
                                        disabled={loading}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                fullWidth: true
                                            },
                                            day: {
                                                sx: {
                                                    '&.Mui-disabled': {
                                                        bgcolor: 'white'
                                                    },
                                                    '&.Mui-selected': {
                                                        bgcolor: 'primary.main',
                                                        opacity: 1
                                                    },
                                                    '&.MuiButtonBase-root-MuiPickersDay-root': {
                                                        opacity: 1
                                                    },
                                                    '&.MuiPickersDay-today': {
                                                        border: 'none'
                                                    },
                                                    bgcolor: 'grey.200',
                                                    opacity: '1 !important',
                                                    fontWeight: 'bold',
                                                    color: 'primary.main',
                                                    borderRadius: 1,
                                                    '&:hover': {
                                                        bgcolor: 'primary.light',
                                                        color: 'white'
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item>
                                <Typography>-</Typography>
                            </Grid>
                            <Grid item md={3} sx={{p: 1}}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        value={selectDate}
                                        onChange={(event) => {
                                            setDate(event);
                                            setDateChange(true);
                                        }}
                                        format="dd/MM/yyyy"
                                        shouldDisableDate={checkDisable}
                                        minDate={addDays(selectPreviousDate, 1)}
                                        disabled={loading}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                fullWidth: true
                                            },
                                            day: {
                                                sx: {
                                                    '&.Mui-disabled': {
                                                        bgcolor: 'white'
                                                    },
                                                    '&.Mui-selected': {
                                                        bgcolor: 'primary.main',
                                                        opacity: 1
                                                    },
                                                    '&.MuiButtonBase-root-MuiPickersDay-root': {
                                                        opacity: 1
                                                    },
                                                    '&.MuiPickersDay-today': {
                                                        border: 'none'
                                                    },
                                                    bgcolor: 'grey.200',
                                                    opacity: '1 !important',
                                                    fontWeight: 'bold',
                                                    color: 'primary.main',
                                                    borderRadius: 1,
                                                    '&:hover': {
                                                        bgcolor: 'primary.light',
                                                        color: 'white'
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item md={2} sx={{p: 1}}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    onClick={() => setDateChange(true)}
                                    disabled={(checkDateSame(selectDate, date) && checkDateSame(selectPreviousDate, previousDate)) || loading}
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </>
                        :
                        <Grid item md={8}>
                            <Box>
                                <CircularProgress/>
                            </Box>
                        </Grid>
                    }
                    {!loading &&
                        <Divider orientation="vertical" flexItem/>
                    }
                    <Grid item md={3} sx={{p: 1}}>
                        {!loading && funds ?
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Select Fund</InputLabel>
                                <Select
                                    size='small'
                                    label='Select Fund'
                                    value={fund}
                                    onChange={(event) => dispatch(selectFund(event.target.value))}
                                >
                                    <MenuItem value={'ALL'}>All Funds</MenuItem>
                                    {funds.map(fund => (
                                        <MenuItem value={fund} key={fund}>{fund}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            :
                            <Box>
                                <CircularProgress/>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
            {selectPreviousDate && selectDate &&
                <AlertDialog
                    title='Are you sure you want to change dates?'
                    info={`Change to: ${formatDate(selectPreviousDate, 'dd-MMM-yyyy')} - ${formatDate(selectDate, 'dd-MMM-yyyy')}`}
                    open={dateChangeWarning}
                    handleClose={() => setDateChange(false)}
                    handleConfirm={() => {
                        dispatch(changeAFRDates({
                            previousDate: selectPreviousDate.getTime(),
                            date: selectDate.getTime()
                        }))
                        setDateChange(false)
                    }}
                />
            }
        </>
    )
}

export default memo(AFRNavBar);