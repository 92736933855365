import {Link as RouterLink} from 'react-router-dom';
// Components
import {Logo, Profile} from "./index";
//@MUI
import {AppBar, Box, Button, Toolbar, Tooltip, Typography} from "@mui/material";
import {Apps as AppsIcon} from "@mui/icons-material"

// Generic TopBar Component for UI Layout
function AuthenticatedTopBar() {
    return (
        <>
            <AppBar
                position='static'
                elevation={0}
                sx={{
                    zIndex: 3000,
                }}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        zIndex: 3000,
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1
                        }}
                    >
                        <Tooltip title="Home">
                            <Button
                                size="large"
                                to={process.env.REACT_APP_HOME_APP || '/'}
                                component={RouterLink}
                                sx={{
                                    flexGrow: 0
                                }}
                            >
                                <AppsIcon
                                    sx={{
                                        color: 'common.white',
                                        width: 30,
                                        height: 30
                                    }}
                                />
                            </Button>
                        </Tooltip>
                        <Typography variant="h5" sx={{flexGrow: 1}}>
                            Asset Fund Register
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flex: 1
                        }}
                    >
                        <Logo/>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'right',
                            flex: 1
                        }}
                    >
                        <Profile/>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default AuthenticatedTopBar;