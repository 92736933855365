import {AssumptionTypeFields} from "../types/AssetFundRegisterTypes";

// Map appropriate UI label for assumption type
export function formatAssumptionLabel(type: string) {
    const assumption = AssumptionTypeFields.find(t => t.value === type);
    if (assumption) {
        return assumption.label
    } else {
        return '-'
    }
}